export const userRoutes = [
  {
    path: "users",
    component: import("../views/users"),
    roles: ["Admin"],
  },
  {
    path: "match-details",
    component: import("../views/front-side/matches"),
    roles: ["Admin"],
  },
  {
    path: "create",
    component: import("../views/front-side/create-match"),
    roles: ["Admin"],
  },
  {
    path: "match-edit/:matchId",
    component: import("../views/front-side/match-edit"),
    roles: ["Admin"],
  },
  {
    path: "player-create",
    component: import("../views/front-side/PlayerForm/PlayerForm"),
    roles: ["Admin"],
  },
  {
    path: "designations",
    component: import("../views/employee/employee_position"),
    roles: ["Admin"],
  },
  {
    path: "committee",
    component: import("../views/employee/employee"),
    roles: ["Admin"],
  },
  {
    path: "player-edit/:playerId",
    component: import("../views/front-side/PlayerForm/PlayerForm"),
    roles: ["Admin"],
  },
  // {
  //   path: "create",
  //   component: import("../views/front-side/create-match"),
  //   roles: ["Admin"]
  // }
];
