export default [
  {
    key: "/",
    to: "/",
    icon: "icon icon-dasbhoard",
    name: "Matches",
    type: "single"
  },
  {
    key: "players",
    to: "/players",
    icon: "icon icon-user",
    name: "Players",
    type: "single"
  },
  {
    key: "Tournaments",
    to: "/tournaments",
    icon: "icon icon-user",
    name: "Tournament",
    type: "single"
  },
  {
    key: "Leagues",
    to: "/leagues",
    icon: "icon icon-user",
    name: "Leagues",
    type: "single"
  },
  {
    key: "create",
    to: "/create",
    icon: "icon icon-chart-radial",
    name: " Create Match",
    type: "single",
    role: "Admin"
  },
  {
    key: "committee",
    to: "/committee",
    icon: "icon icon-user",
    name: " Committee",
    type: "single",
    role: "Admin"
  },
  {
    key: "designations",
    to: "/designations",
    icon: "icon icon-card",
    name: " Designations",
    type: "single",
    role: "Admin"
  },
  {
    key: "users",
    to: "/users",
    icon: "icon icon-badge",
    name: "Users",
    type: "single",
    role: "Admin"
  }
];
