import axios from "axios";
const ApiURL = `/api`;
const BackEND = `/api`;
// const ApiURL = `http://localhost:8000/api`;
// const BackEND = `http://localhost:8000`;
// const ApiURL = `http://bkingsportal.com/api`;
// const BackEND = `http://bkingsportal.com`;

export default axios.create({
  baseURL: ApiURL,
  headers: {
    "Content-Type": "application/json"
  }
});

export const API = ApiURL;
export const BackEnd = BackEND;
