import React from "react";
import { Button, Checkbox, Form, Input, Icon, notification } from "antd";
import { Link, withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { userSignUp } from "../store/actions/Auth";
import axios from "util/Api";

import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";

const FormItem = Form.Item;
var timeout = null;
class SignUp extends React.Component {
  state = {
    uniqueEmp: true
  };
  doCheck = evt => {
    var searchText = evt.target.value;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => this.checkUniqueId(searchText), 800);
  };

  checkUniqueId = searchText => {
    axios
      .get("employees-id-check/" + searchText)
      .then(response => {
        var uniqueEmp = response.data === true ? true : false;
        this.setState({ uniqueEmp });
      })
      .catch(error => {
        this.setState({ uniqueEmp: false });

        console.log("Error****:", error.message);
      });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (!this.state.uniqueEmp) {
          this.props.userSignUp(this.props.history, values);
        } else {
          notification["error"]({
            message: "No Employee ID Match!",
            description:
              "Employee ID does not match with the Database. Contact System Admin."
          });
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                {/* <img src="https://via.placeholder.com/272x395" alt="Neature" /> */}
              </div>
              <div className="gx-app-logo-wid">
                <h1>
                  <IntlMessages id="app.userAuth.signUp" />
                </h1>
                <p>
                  <IntlMessages id="app.userAuth.bySigning" />
                </p>
                <p>
                  <IntlMessages id="app.userAuth.getAccount" />
                </p>
              </div>
              <div className="gx-app-logo ">
                <img alt="Logo" src={require("assets/images/logo.png")} />
              </div>
            </div>

            <div className="gx-app-login-content">
              <Form
                onSubmit={this.handleSubmit}
                className="gx-signup-form gx-form-row0"
              >
                <FormItem>
                  {getFieldDecorator("name", {
                    rules: [
                      { required: true, message: "Please input your name!" }
                    ]
                  })(<Input placeholder="Name" />)}
                </FormItem>

                <FormItem>
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        required: true,
                        type: "email",
                        message: "The input is not valid E-mail!"
                      }
                    ]
                  })(<Input placeholder="Email" />)}
                </FormItem>
                <FormItem>
                  {getFieldDecorator("employee_id", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your Employee ID!"
                      }
                    ]
                  })(
                    <Input
                      id="employee_id"
                      style={{ width: "100%" }}
                      placeholder="Employee ID"
                      onChange={this.doCheck}
                      suffix={
                        <Icon
                          type={
                            this.state.uniqueEmp === false
                              ? "check-circle"
                              : "stop"
                          }
                          theme={this.state.uniqueEmp === false ? "filled" : ""}
                          style={{
                            color:
                              this.state.uniqueEmp === false ? "#52c41a" : "red"
                          }}
                        />
                      }
                    />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator("password", {
                    rules: [
                      { required: true, message: "Please input your Password!" }
                    ]
                  })(<Input type="password" placeholder="Password" />)}
                </FormItem>
                <FormItem>
                  {getFieldDecorator("remember", {
                    valuePropName: "checked",
                    initialValue: true
                  })(
                    <Checkbox>
                      <IntlMessages id="appModule.iAccept" />
                    </Checkbox>
                  )}
                  <span className="gx-link gx-signup-form-forgot">
                    <IntlMessages id="appModule.termAndCondition" />
                  </span>
                </FormItem>
                <FormItem>
                  <Button
                    type="primary"
                    className="gx-mb-0"
                    htmlType="submit"
                    disabled={this.state.uniqueEmp}
                  >
                    Send Request
                  </Button>
                  <span>
                    <IntlMessages id="app.userAuth.or" />
                  </span>{" "}
                  <Link to="/signin">
                    <IntlMessages id="app.userAuth.signIn" />
                  </Link>
                </FormItem>
              </Form>
            </div>
            <InfoView />
          </div>
        </div>
      </div>
    );
  }
}

const WrappedSignUpForm = Form.create()(SignUp);

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};

export default withRouter(
  connect(
    mapStateToProps,
    { userSignUp }
  )(WrappedSignUpForm)
);
