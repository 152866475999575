import React, { Component } from "react";
import {
  Input,
  AutoComplete,
  Icon,
  Layout,
  Menu,
  message,
  Popover,
  Select,
  Spin
} from "antd";
import { connect } from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";
import axios from "util/Api";
import { debounce, throttle } from 'lodash';
import UserInfo from "components/UserInfo";
import { Link } from "react-router-dom";
import HorizontalNav from "../HorizontalNav";
import {
  switchLanguage,
  toggleCollapsedSideNav
} from "../../../store/actions/Setting";
import IntlMessages from "../../../util/IntlMessages";
import { withRouter } from "react-router-dom";

const { Header } = Layout;

const Option = Select.Option;
const menu = (
  <Menu onClick={handleMenuClick}>
    <Menu.Item key="1">Products</Menu.Item>
    <Menu.Item key="2">Apps</Menu.Item>
    <Menu.Item key="3">Blogs</Menu.Item>
  </Menu>
);

function handleMenuClick(e) {
  message.info("Click on menu item.");
}



class HorizontalDark extends Component {


  constructor(props) {
    super();
    this.state = {
      searchText: "",
      loading: false,
      data: [],
      searchType: "player"
    };
    this.asyncGetSearchResult = debounce(this.asyncGetSearchResult, 1000);

  }
  handleChange = (value) => {
    this.setState({
      searchType: value,
      data: []
    }, () => {
      this.asyncGetSearchResult(this.state.searchText);
    });
  }
  onSearchBoxSearch = (value) => {
    this.asyncGetSearchResult(value);
    this.setState({
      searchText: value
    });
  }
  onClickOptionSearch = (value) => {

    if (this.state.searchType === "player") {
      this.props.history.push("/player/" + value.id);
    }
    else if (this.state.searchType === "match") {
      this.props.history.push("/match-details/" + value.id);
    }
    else {
      this.props.history.push(`/?filter_type=${this.state.searchType}&filter_id=${value.id}&filter_name=${value.text}`);
    }

  }

  asyncGetSearchResult = (value) => {
    if (!value) {
      return;
    }
    this.setState({
      loading: true
    })
    axios
      .get(`search-all?type=${this.state.searchType}&text=${value}`)
      .then(response => {
        this.setState({
          data: response.data,
          loading: false
        })
      })
      .catch(error => {
        console.log("Error****:", error.message);
        this.setState({
          loading: false
        })
      });
  }
  languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      {/* <ul className="gx-sub-popover">
        {languageData.map(language =>
          <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
            this.props.switchLanguage(language)
          }>
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`}/>
            <span className="gx-language-text">{language.name}</span>
          </li>
        )}
      </ul> */}
    </CustomScrollbars>
  );

  render() {
    const { locale, navCollapsed } = this.props;
    const optionSearch = this.state.data.map(data => {
      return <Option key={`op_${data.id}`} value={data.text} onClick={() => this.onClickOptionSearch(data)}>
        {data.text}
        {data.extra && <span key={`span_${data.id}`}><br /><small>{data.extra}</small></span>}
      </Option>
    });

    return (
      <div className="gx-header-horizontal gx-header-horizontal-dark">
        <div className="gx-header-horizontal-top">
          <div className="gx-container">
            <div className="gx-header-horizontal-top-flex">
              {/* <div className="gx-header-horizontal-top-left">
                <i className="icon icon-alert gx-mr-3" />
                <p className="gx-mb-0 gx-text-truncate">
                  <IntlMessages id="app.announced" />
                </p>
              </div> */}

              <ul className="gx-login-list">
                {!this.props.authUser && <Link to="/signin"> <li>Login</li> </Link>}

              </ul>
            </div>
          </div>
        </div>

        <Header className="gx-header-horizontal-main">
          <div className="gx-container">
            <div className="gx-header-horizontal-main-flex">
              <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3">
                <i
                  className="gx-icon-btn icon icon-menu"
                  onClick={() => {
                    this.props.toggleCollapsedSideNav(!navCollapsed);
                  }}
                />
              </div>

              <Link
                to="/"
                className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo" >
                <img
                  alt=""
                  src={require("assets/images/player.svg")}
                  width="100px"
                  height="100px"
                />
                <h5>
                  <img
                    alt=""
                    src={require("assets/images/logo-white.png")}
                    width="120px"
                  // height="90px"
                  />

                </h5>
              </Link>
              <div className="gx-header-search gx-d-none gx-d-lg-flex">


                <AutoComplete
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ width: 300 }}
                  style={{ width: '300px' }}
                  allowClear={true}
                  dataSource={optionSearch}
                  placeholder="Search in app"
                  onSearch={this.onSearchBoxSearch}
                  optionLabelProp="value"
                >

                  <input className="ant-input" style={{ color: "white" }} type="search" />
                </AutoComplete>

                <Select
                  // className="gx-select"
                  defaultValue="player"
                  style={{ width: "50%" }}
                  onChange={this.handleChange}
                >
                  <Option value="player">Player</Option>
                  <Option value="match">Match</Option>
                  <Option value="tournament">Tournament</Option>
                  <Option value="league">league</Option>
                </Select>
                {this.state.loading && <Spin className="gx-ml-4" />}

              </div>

              <ul className="gx-header-notifications gx-ml-auto">

                <li className="gx-user-nav">
                  <UserInfo />

                </li>
              </ul>
            </div>
          </div>
        </Header>
        <div className="gx-header-horizontal-nav gx-d-none gx-d-lg-block">
          <div className="gx-container">
            <div className="gx-header-horizontal-nav-flex">
              <HorizontalNav />
            </div>
          </div>
        </div>
      </div >
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { locale, navCollapsed } = settings;
  return { locale, navCollapsed, authUser: auth.authUser };
};
export default withRouter(connect(mapStateToProps, {
  toggleCollapsedSideNav,
  switchLanguage
})(HorizontalDark));
