import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import MenuList from "./Menu";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
const SubMenu = Menu.SubMenu;

class SidebarContent extends Component {
  getNoHeaderClass = navStyle => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = navStyle => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };
  drawMenuItem = element => {
    if (
      (this.props.roles && this.props.roles.includes(element.role)) ||
      element.role === "auth"
    ) {
      return (
        <Menu.Item key={element.key}>
          <Link to={element.to}>
            <i className={element.icon} />
            {element.name}
          </Link>
        </Menu.Item>
      );
    }
  };
  render() {
    const { themeType, navStyle, pathname } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[1];
    return (
      <Auxiliary>
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div
            className={`gx-sidebar-notifications ${this.getNoHeaderClass(
              navStyle
            )}`}
          >
            <UserProfile />
            <AppsNavigation />
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
              mode="inline"
            >
              {MenuList.map((element, index) => {
                if (element.type === "group") {
                  if (
                    (this.props.roles &&
                      this.props.roles.includes(element.role)) ||
                    element.role === "auth"
                  ) {
                    return (
                      <SubMenu
                        key={element.key}
                        className={this.getNavStyleSubMenuClass(navStyle)}
                        title={
                          <span>
                            <i className={element.icon} />
                            {element.name}
                          </span>
                        }
                      >
                        {element.child.map(element => {
                          return this.drawMenuItem(element);
                        })}
                      </SubMenu>
                    );
                  } else return <div key={index}></div>;
                } else {
                  return this.drawMenuItem(element);
                }
              })}
            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings, auth }) => {
  const { roles } = auth;
  const { navStyle, themeType, locale, pathname } = settings;
  return { navStyle, themeType, locale, pathname, roles };
};
export default connect(mapStateToProps)(SidebarContent);
