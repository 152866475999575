import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";
// import IntlMessages from "../../util/IntlMessages";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";
import MenuList from "../Sidebar/Menu";

const SubMenu = Menu.SubMenu;

class HorizontalNav extends Component {
  getNavStyleSubMenuClass = navStyle => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";
    }
  };
  drawMenuItem = element => {
    // console.log(this.props.authUser);

    if (this.props.authUser) {
      return (
        <Menu.Item key={element.key} >
          <Link to={element.to}>
            <i className={element.icon} />
            {element.name}
          </Link>
        </Menu.Item>
      );
    } else if (!this.props.authUser && !element.role) {
      return (
        <Menu.Item key={element.key} >
          <Link to={element.to}>
            <i className={element.icon} />
            {element.name}
          </Link>
        </Menu.Item>
      );
    }
  };
  render() {
    const { pathname, navStyle } = this.props;
    const selectedKeys =  pathname ? pathname.substr(1) : "/";
    const defaultOpenKeys = selectedKeys.split("/")[1];
    return (
      <Menu
        defaultOpenKeys={[defaultOpenKeys]}
        selectedKeys={[selectedKeys]}
        mode="horizontal"
      >
        {MenuList.map((element, index) => {
          if (element.type === "group") {
            return (
              <SubMenu
                key={element.key}
                className={this.getNavStyleSubMenuClass(navStyle)}
                // style={{ backgroundColor: "grey" }}
                title={
                  <span style={{ color: "#545454" }}>
                    <i className={element.icon} />
                    {" " + element.name}
                  </span>
                }
              >
                {element.child.map(element => {
                  return this.drawMenuItem(element);
                })}
              </SubMenu>
            );
          } else {
            return this.drawMenuItem(element);
          }
        })}
      </Menu>
    );
  }
}

HorizontalNav.propTypes = {};
const mapStateToProps = ({ settings, auth }) => {
  const { themeType, navStyle, pathname, locale } = settings;
  return { themeType, navStyle, pathname, locale, authUser: auth.authUser };
};
export default connect(mapStateToProps)(HorizontalNav);
