import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET
} from "../../constants/ActionTypes";
import axios from "util/Api";
import { notification } from "antd";

export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = (history, { email, password, name, employee_id }) => {
  // console.log(email, password);
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .post("auth/register", {
        email: email,
        password: password,
        employee_id: employee_id,
        name: name
      })
      .then(({ data }) => {
        if (data.result) {
          dispatch({ type: FETCH_SUCCESS });
          notification["success"]({
            message: "User created successfully!",
            description:
              "You will be able to login after the verification from system admin"
          });
          history.push("/signin");
        } else {
          // console.log("payload: data.error", data.error);
          dispatch({
            type: FETCH_ERROR,
            payload: "Error: " + JSON.stringify(data.error)
          });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const userSignIn = ({ email, password }) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .post("auth/login", {
        email: email,
        password: password
      })
      .then(response => {
        if (response.data.result) {
          let data = response.data;

          localStorage.setItem(
            "token",
            JSON.stringify(data.token.access_token)
          );
          axios.defaults.headers.common["access-token"] =
            "Bearer " + data.token.access_token;
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + data.token.access_token;

          dispatch({
            type: USER_TOKEN_SET,
            payload: data.token.access_token
          });
          dispatch({ type: FETCH_SUCCESS });
          dispatch(getUser());
        } else {
          dispatch({ type: FETCH_ERROR, payload: response.data.message });
        }
      })
      .catch(error => {
        dispatch({ type: FETCH_ERROR, payload: "Un-authorized user" });
        console.log("Error****:", "Un-authorized user");
      });
  };
};
export const getUser = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .get("auth/me")
      .then(({ data }) => {
        if (data && data.result) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_DATA, payload: data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: SIGNOUT_USER_SUCCESS });
        dispatch({ type: FETCH_ERROR, payload: "Token Expired" });
      });
  };
};

export const userSignOut = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .post("auth/logout")
      .then(() => {
        localStorage.removeItem("token");

        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: SIGNOUT_USER_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};
