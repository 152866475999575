import React, { Component } from "react";
import { connect } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "store/actions/Auth";

class UserInfo extends Component {
  render() {
    const userMenuOptions = (
      <ul className="gx-user-popover">
        {/* <li>My Account</li>
        <li>Connections</li> */}
        <li onClick={() => this.props.userSignOut()}>Logout</li>
      </ul>
    );

    return this.props.authUser ? (
      <Popover
        overlayClassName="gx-popover-horizantal"
        placement="bottomRight"
        content={userMenuOptions}
        trigger="hover"
      >
        <Avatar src="/avatar.png" className="gx-avatar gx-pointer" alt="" />
        <small>{this.props.authUser.name}</small>
      </Popover>
    ) : (
      <span></span>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;
  return { authUser: auth.authUser };
}

export default connect(mapStateToProps, { userSignOut })(UserInfo);
