import React from "react";
import { Redirect, Route } from "react-router-dom";

// import asyncComponent from "util/asyncComponent";

export const RestrictedRoute = ({
  component: Component,
  roles,
  authUserRoles,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      const match = (authUserRoles, roles) => {
        return authUserRoles.some(item => roles.includes(item));
      };

      if (roles && authUserRoles && match(authUserRoles, roles)) {
        return <Component {...props} />;
      } else if (!roles) {
        return <Component {...props} />;
      } else if (!authUserRoles) {
        return (
          <Redirect
            to={{
              pathname: "/signin",
              state: {
                from: props.location
              }
            }}
          />
        );
      } else {
        return (
          <Redirect
            to={{
              pathname: "/error-500",
              state: {
                from: props.location
              }
            }}
          />
        );
      }
    }}
  />
);
