import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";
import { RestrictedRoute } from "./restrictedRoute";
import { userRoutes } from "./users.route";

const routerMerge = [...userRoutes];

const App = ({ match, authUserRoles }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      {/* <Route
        path={`${match.url}/`}
        component={asyncComponent(() => import("../views/front-side/matches"))}
      />{" "} */}
      <Route
        path={`${match.url}/`}
        component={asyncComponent(() => import("../views/front-side/matches"))}
      />{" "}
     
      <Route
        path={`${match.url}players`}
        component={asyncComponent(() => import("../views/front-side/players"))}
      />{" "}
      <Route
        path={`${match.url}player/:playerId`}
        component={asyncComponent(() =>
          import("../views/front-side/player-details")
        )}
      />{" "}
    
      <Route
        path={`${match.url}leagues`}
        component={asyncComponent(() => import("../views/front-side/league"))}
      />{" "}
      <Route
        path={`${match.url}tournaments`}
        component={asyncComponent(() =>
          import("../views/front-side/tournament")
        )}
      />{" "}
      <Route
        path={`${match.url}match-details/:matchId`}
        component={asyncComponent(() =>
          import("../views/front-side/match-view-mode")
        )}
      />{" "}
      {routerMerge.map((props) => (
        <RestrictedRoute
          path={`${match.url}${props.path}`}
          roles={props.roles && props.roles}
          authUserRoles={authUserRoles}
          component={asyncComponent(() => props.component)}
        />
      ))}
      <Route
        component={asyncComponent(() => import("../views/errorPages/500"))}
      />
    </Switch>
  </div>
);

export default App;
